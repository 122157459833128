import PrintableImageButton from '~/modules/printables/components/PrintableImageButton'

interface PrintableProps {
  asset: {
    id: string
    label: string
    data: {
      isFreebie: boolean
      slug: string
      image: {
        id: string
        imageFile: {
          url: string
        }
      }
    }
  }[]
}

export function Printable({ asset }: PrintableProps) {
  const itemsCount = asset.length

  // Determine the appropriate grid column class based on the number of items
  let gridColsClass
  if (itemsCount === 1) {
    gridColsClass = 'grid-cols-1'
  } else if (itemsCount === 2) {
    gridColsClass = 'grid-cols-2'
  } else {
    gridColsClass = 'grid-cols-2 md:grid-cols-3'
  }

  return (
    <div className={`mb-4 grid ${gridColsClass} gap-4 sm:gap-6`}>
      {asset.map((data: any) => {
        const printable = {
          id: data?.id ?? '',
          isFreebie: data?.data?.isFreebie,
          title: data?.label,
          slug: data?.slug,
          image: {
            id: data?.data?.image?.id,
            imageFile: {
              url: data?.data?.image?.imageFile?.url
            }
          }
        }
        return <PrintableImageButton key={data.id} printable={printable} />
      })}
    </div>
  )
}
