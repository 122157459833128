import { Link } from '@remix-run/react'

interface ImageProps {
  asset: {
    id: string
    label: string
    data: {
      imageFile: {
        url: string
      }
    }
  } | null
  url: {
    discriminant: boolean
    value: {
      href: string
    }
  }
  targetBlank?: boolean
}

export function Image({ asset, url, targetBlank }: ImageProps) {
  const imageElement = <img src={asset?.data?.imageFile?.url} alt={asset?.label} className="my-4 w-full rounded-md" />

  return (
    <>
      {url?.discriminant ? (
        <Link
          to={url.value?.href || ''}
          prefetch="intent"
          className="w-full"
          target={targetBlank ? '_blank' : undefined}
          rel={targetBlank ? 'noreferrer nofollow' : undefined}>
          {imageElement}
        </Link>
      ) : (
        imageElement
      )}
    </>
  )
}
