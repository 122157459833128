interface CarouselProps {
  items: {
    id: string
    label: string
    data: {
      imageFile: {
        url: string
      }
    }
  }[]
}

export function Carousel({ items = [] }: CarouselProps) {
  return (
    <div
      className="
  grid snap-y snap-mandatory grid-flow-col grid-cols-[repeat(4,minmax(min(100%,_640px),_100%))] gap-2 overflow-y-scroll">
      {items.map(item => {
        return (
          <div key={item.id} className="my-4 box-border snap-center snap-always rounded-md bg-[#eff3f6] p-2">
            <img
              src={item.data?.imageFile?.url}
              alt={item.label}
              className="h-[320px] w-full rounded-md object-cover object-center"
            />
            <h1 className="mt-2 text-xl leading-none">{item.label}</h1>
          </div>
        )
      })}
    </div>
  )
}
