import { useMemo } from 'react'
import type { DocumentRendererProps } from '@keystone-6/document-renderer'
import { DocumentRenderer } from '@keystone-6/document-renderer'

import { createRenderers } from './DocumentFieldRenderers'
import { createComponentBlockRenderers } from './ComponentBlockRenderers'

export interface GenericDocumentProps {
  document: DocumentRendererProps['document']
  targetBlank?: boolean
}

const GenericDocument = ({ targetBlank, document }: GenericDocumentProps) => {
  const renderers = useMemo(() => createRenderers({ targetBlank }), [targetBlank])
  const componentBlockRenderers = useMemo(() => createComponentBlockRenderers({ targetBlank }), [targetBlank])
  return <DocumentRenderer document={document} renderers={renderers} componentBlocks={componentBlockRenderers} />
}

export default GenericDocument
