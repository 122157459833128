import Button from '../../Button'

interface HeroProps {
  title: string
  content: string
  image: {
    id: string
    label: string
    data: {
      imageFile: {
        url: string
      }
    }
  }
  cta: {
    discriminant: boolean
    value: {
      text: string
      href: string
    }
  }
  caption: {
    discriminant: boolean
    value: string
  }
  targetBlank?: boolean
}

export function Hero({ title, content, image, cta, caption, targetBlank }: HeroProps) {
  return (
    <div className="mb-4 flex flex-col items-center justify-center">
      <div
        className="flex min-h-[400px] w-full flex-col items-stretch justify-evenly bg-white bg-cover bg-center"
        style={{ backgroundImage: `url(${image.data?.imageFile?.url})` }}>
        <div className="m-4 text-center text-5xl font-bold text-white text-shadow-custom">{title}</div>
        <div className="m-3 text-center text-2xl font-bold text-white text-shadow-custom">{content}</div>
        {cta?.discriminant && (
          <div className="text-center">
            <Button to={cta.value?.href} text={cta.value?.text} size="lg" intent="primary" newWindow={targetBlank} />
          </div>
        )}
      </div>
      {caption?.discriminant ? <div style={{ textAlign: 'center' }}>{caption.value}</div> : null}
    </div>
  )
}
