import { Children, isValidElement } from 'react'
import { Link } from '@remix-run/react'
import type { DocumentRendererProps } from '@keystone-6/document-renderer'
import classnames from 'classnames'

import styles from './DocumentFieldRenderers.module.css'

interface CreateRenderersProps {
  targetBlank?: boolean
}

export const createRenderers = ({ targetBlank }: CreateRenderersProps): DocumentRendererProps['renderers'] => ({
  inline: {
    link: ({ children, href }) => {
      return (
        <a
          href={href}
          className="text-baseBlue hover:underline"
          target={targetBlank ? '_blank' : '_self'}
          rel={targetBlank ? 'noopener noreferrer' : undefined}>
          {children}
        </a>
      )
    },
    relationship({ relationship, data }) {
      if (relationship === 'linkedFile') {
        if (data === null || data.data === undefined) {
          return <span>[unknown file]</span>
        } else {
          return (
            <Link to={`/files/${data.id}`} className="font-bold hover:underline" prefetch="none">
              {data.data.name}
            </Link>
          )
        }
      }
      return <span>{data?.label || 'Unknown link'}</span>
    }
  },
  block: {
    list: ({ type, children }) => {
      const cloned = Children.map(children, child => <li>{child}</li>)
      return type === 'unordered' ? (
        <ul className="list-outside list-disc pl-8">{cloned}</ul>
      ) : (
        <ol className="list-outside pl-8">{cloned}</ol>
      )
    },
    layout: ({ children, layout }) => (
      <div
        className={classnames('md:grid md:gap-4', styles.layoutContainer)}
        style={{ gridTemplateColumns: layout.map(fraction => `${fraction}fr`).join(' ') }}>
        {children.map((element, i) => {
          return (
            <div key={i} className={classnames('space-y-2', styles.layoutChild)}>
              {element}
            </div>
          )
        })}
      </div>
    ),
    paragraph: ({ children, textAlign }) => {
      const isEmptyTextNode = Children.toArray(children).every(
        child => isValidElement(child) && child.props.node?.text === ''
      )

      const textAlignClass = textAlign ? `text-${textAlign}` : ''

      return isEmptyTextNode ? (
        <br />
      ) : (
        <p className={classnames('mb-4 whitespace-pre-wrap break-words', textAlignClass)}>{children}</p>
      )
    },

    divider: () => <hr className="my-8 border-t-2 border-baseGray dark:border-tigerGray" />
  }
})
